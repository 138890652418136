import React from "react";
import $ from "jquery";
import styled from "styled-components";
import GutterElement from "./GutterElement";
import ContentWithCallout from "./ContentWithCallout";
import Row from "../Row";
import { Font, media, Color, rem } from "../../utils/style";
import { graphql } from "gatsby";

const Article = styled(Row)`
  color: ${Color.ritualBlue};
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ArticleQuestion = styled.h3`
  ${Font.circular} position: relative;
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  letter-spacing: -0.2px;
  padding-left: 70px;
  margin: 0 0 26px;

  ${media.mobile`
    padding-left: 45px;
    font-size: ${rem(22)};
    line-height: ${rem(30)};
  `} &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: ${Color.ritualYellow};
  }

  &:before {
    content: "";
    position: absolute;
    left: 2px;
    top: 34px;
    width: 28px;
    height: 3px;
    background-color: ${Color.ritualYellow};
  }
`;

export default class ArticleQA extends React.Component {
  constructor(props) {
    super(props);
    this.annotation = null;
    this.questionRef = null;
    this.state = { annotationTop: 0, hideGutter: false };
  }

  setAnnotationTop(top) {
    this.setState({
      annotationTop: top + $(this.questionRef).outerHeight(),
    });
  }

  setHideGutter(hideGutter) {
    this.setState({
      hideGutter: hideGutter,
    });
  }

  render() {
    let { question, answer, gutterItem } = this.props.data;
    let firstGutterItem = gutterItem ? gutterItem[0] : null;
    let { annotationTop, hideGutter } = this.state;
    return (
      <Article>
        <GutterElement
          ref={p => {
            this.annotation = p;
          }}
          {...firstGutterItem}
          overrideContent={this.props.gutterContent}
          top={annotationTop}
          hideGutter={hideGutter}
          className="d-md-block"
        />
        <div className="col-12 col-md-7 offset-md-1">
          <ArticleQuestion ref={p => (this.questionRef = p)}>
            {question}
          </ArticleQuestion>
          <ContentWithCallout
            type="answer"
            gutterItem={firstGutterItem}
            hideGutter={this.setHideGutter.bind(this)}
            onCalloutMoved={this.setAnnotationTop.bind(this)}
            content={answer.childMarkdownRemark.html}
          />
        </div>
      </Article>
    );
  }
}

export const myQuery = graphql`
  fragment articleQABlock on ContentfulContentInterviewQA {
    question
    answer {
      childMarkdownRemark {
        html
      }
    }
    contentBody: answer {
      childMarkdownRemark {
        timeToRead
      }
    }
    gutterItem {
      ... on ContentfulGutterAnnotation {
        ...gutterSummary
      }
      ... on ContentfulGutterCta {
        ...gutterCtaSummary
      }
      ... on ContentfulGutterImage {
        ...gutterImageSummary
      }
    }
  }
`;
