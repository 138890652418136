import React from "react";
import styled from "styled-components";
import MagicLink from "../MagicLink";
import Img from "gatsby-image";
import Row from "../Row";
import Container from "../Container";
import Text from "../Text";
import RitualButton from "../global/RitualButton";
import { Font, media, Color, rem, maxLines } from "../../utils/style";
import { calculateReadingTime } from "../../utils/readingTime";

const RelatedWrapper = styled.div.attrs({
  role: "section",
})`
  margin-top: 50px;
  background-color: rgba(225, 233, 240, 0.56);

  ${media.tablet`
    margin-top: 30px;
  `};
`;

const RelatedHeader = styled.div.attrs({
  className: "col-12",
})`
  h2 {
    ${Font.circular};
    text-align: center !important;
    font-size: ${rem(48)};
    font-weight: 500;
    line-height: ${rem(54)};
    letter-spacing: ${rem(-1.4)};
    color: ${Color.ritualBlue};
    margin: ${rem(120)} 0 ${rem(55)};

    ${media.tablet`
      font-size: ${rem(30)};
      line-height: ${rem(36)};
      letter-spacing: ${rem(-0.4)};
      margin: ${rem(80)} 0 ${rem(24)};
    `};
  }
`;

const RelatedArticle = styled.div.attrs(p => ({
  className: `col-12 col-sm-6 col-lg-5 ${p.i === 0 ? "offset-lg-1" : ""}`,
}))`
  &:first-child {
    padding-left: 0 !important;
  }

  &:last-child {
    padding-right: 0 !important;
  }

  ${media.tablet`
    &:first-child {
      padding-left: 0 !important;
      padding-right: 13px !important;
    }

    &:last-child {
      padding-right: 0 !important;
      padding-left: 13px !important;
    }
  `};

  ${media.mobile`
    &:first-child {
      padding-left: 20px !important;
      padding-right: 20px !important;
      margin-bottom: 24px;
    }

    &:last-child {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }
  `};
`;

const RelatedArticleContent = styled.div.attrs({
  className: "content",
})`
  position: relative;
  text-decoration: none;
  padding: 50px;
  height: 427px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  background-color: ${props => props.color};

  img {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
    }
  }

  ${media.mobile`
    &:hover img {
      transform: none;
    }
  `};

  ${media.notDesktop`
    height: 340px;
    padding: 40px 24px;
  `};

  ${media.tablet`
    padding: 24px;
  `};
`;

const RelatedFloatBottom = styled.div`
  align-self: flex-end;
  max-width: 320px;
  text-decoration: none;
  z-index: 2;
  color: ${props => (props.color ? props.color : Color.ritualBlue)};

  span,
  h2,
  p {
    color: inherit;
  }

  &:hover {
    text-decoration: none;
    color: ${props => (props.color ? props.color : Color.ritualBlue)};
  }
`;

const RelatedReadtime = styled.span`
  ${Font.circular};
  display: block;
  margin: 0;
  padding: 0;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin: 0 0 ${rem(5)};
`;

const RelatedTitle = styled.p`
  ${Font.circular};
  font-size: ${rem(24)};
  line-height: ${rem(32)};
  font-weight: 400;
  margin: 0;
  padding: 0;

  ${media.tablet`
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
  `};
`;

const RelatedPreview = styled.p`
  ${Font.dutch}
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  margin: ${rem(16)} 0 0;
  ${maxLines(30, 3)};

  ${media.tablet`
    ${maxLines(26, 3)}
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `};
`;

const StyledArticlesButton = styled(RitualButton)`
  color: ${Color.ritualBlue};
  background-color: transparent;
  margin: auto;
`;

const StyledArticlesButtonContainer = styled(Container)`
  display: flex;
  padding: ${rem(56)} 0px ${rem(120)};

  ${media.mobile`
    padding: ${rem(40)} 0px ${rem(80)};
  `};
`;

export default class ArticleRelated extends React.Component {
  renderArticles() {
    let { data, showPreview = false } = this.props;
    if (!data || !data.length) {
      return null;
    }
    return (
      data &&
      data.map((node, i) => {
        let {
          title,
          previewText,
          heroImage,
          primaryContent,
          heroBackgroundColor,
          heroSecondaryColor,
        } = node;
        let readingTime = calculateReadingTime(primaryContent);
        return (
          <RelatedArticle key={`article-${i}`} i={i}>
            <MagicLink to={node} ariaLabel={title}>
              <RelatedArticleContent color={heroBackgroundColor}>
                <Img
                  className="d-sm-none"
                  fixed={heroImage.mobile}
                  loading="eager"
                  color={heroBackgroundColor}
                  alt={title}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "1",
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
                <Img
                  className="d-none d-sm-block"
                  fixed={heroImage.desktop}
                  loading="eager"
                  color={heroBackgroundColor}
                  alt={title}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "1",
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
                <RelatedFloatBottom color={heroSecondaryColor} to={node}>
                  <RelatedReadtime>
                    <Text
                      id="hub.hero.reading-time"
                      defaultMessage="{readTime} min read"
                      values={{ readTime: readingTime }}
                    />
                  </RelatedReadtime>
                  <RelatedTitle>{title}</RelatedTitle>
                  {showPreview && (
                    <RelatedPreview>{previewText}</RelatedPreview>
                  )}
                </RelatedFloatBottom>
              </RelatedArticleContent>
            </MagicLink>
          </RelatedArticle>
        );
      })
    );
  }

  render() {
    return (
      <RelatedWrapper>
        <Container>
          <Row>
            <RelatedHeader>
              <h2 className="mt-10">Related Articles</h2>
            </RelatedHeader>
          </Row>
          <Row>{this.renderArticles()}</Row>
        </Container>
        <StyledArticlesButtonContainer>
          <StyledArticlesButton to={"/articles"}>
            See All Articles
          </StyledArticlesButton>
        </StyledArticlesButtonContainer>
      </RelatedWrapper>
    );
  }
}
