import React, { Component } from "react";
import Helmet from "react-helmet";
import config from "../../utils/siteConfig";
import GeneralTags from "./GeneralTags";

class ArticleSEO extends Component {
  render() {
    const defaultTitle = config.siteTitle;
    const {
      title,
      description,
      image,
      image: { url: imgUrlPartial, width: imgWidth, height: imgHeight },
      pagePath,
    } = this.props;

    const pageUrl = `${config.siteUrl}/${pagePath}`;
    const imgUrl = `https:${imgUrlPartial}`;

    // Overwrite properties below with new image attributes
    let meta = [
      { name: "image", content: imgUrl },
      { property: "og:type", content: "article" },
      { property: "og:image", content: imgUrl },
      { property: "og:image:width", content: imgWidth },
      { property: "og:image:height", content: imgHeight },
      { name: "twitter:image", content: imgUrl },
    ];

    let schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": config.siteUrl,
              name: defaultTitle,
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": pageUrl,
              name: title,
            },
          },
        ],
      },
      {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        url: pageUrl,
        name: title,
        alternateName: config.siteTitleAlt || "",
        headline: title,
        image: {
          "@type": "ImageObject",
          url: imgUrl,
          width: imgWidth,
          height: imgHeight,
        },
        author: {
          "@type": "Person",
          name: config.author,
          url: config.authorUrl,
        },
        publisher: {
          "@type": "Organization",
          name: config.publisher,
          url: config.siteUrl,
          logo: {
            "@type": "ImageObject",
            width: 512,
            height: 512,
            url: config.siteLogo,
          },
        },
        mainEntityOfPage: pageUrl,
      },
    ];

    return (
      <div>
        <GeneralTags
          pagePath={pagePath}
          title={title}
          description={description}
          image={image}
        />
        <Helmet meta={meta}>
          <script id="blog-schema" type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>
        </Helmet>
      </div>
    );
  }
}

export default ArticleSEO;
