import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Utils
import { media } from "../utils/style";
import config from "../utils/siteConfig";
import { calculateReadingTime } from "../utils/readingTime";

// Components
import Container from "../components/Container";
import ArticleQA from "../components/hub/ArticleQA";
import ArticleMythFact from "../components/hub/ArticleMythFact";
import ArticleStandaloneAnswer from "../components/hub/ArticleStandaloneAnswer";
import ArticleStandaloneImage from "../components/hub/ArticleStandaloneImage";
import ArticleBlockQuote from "../components/hub/ArticleBlockQuote";
import ArticleContentGeneric from "../components/hub/ArticleContentGeneric";
import ArticleEssentialTakeaways from "../components/hub/ArticleEssentialTakeaways";
import ArticleInterviewerBio from "../components/hub/ArticleInterviewerBio";
import ArticleRelated from "../components/hub/ArticleRelated";
import ArticleExperts from "../components/hub/ArticleExperts";
import ArticleShare from "../components/hub/ArticleShare";
import VideoOverlay from "../components/hub/VideoOverlay";
import ProductCards from "../components/home/ProductCards";
import ArticleSEO from "../components/seo/Article";
import ProductCategoryCards from "../components/product/ProductCategoryCards";
import ArticleHero from "../components/hub/ArticleHero";
import PageHero from "../components/PageHero";
import ArticleReadProgress from "../components/hub/ArticleReadProgress";
import StickyProductNav from "../components/global/StickyProductNav";
import Text from "../components/Text";

// Styled Elements
const ArticleContainer = styled(Container).attrs({
  role: "section",
})``;

const CardsSection = styled.section``;

const ProductCardContainer = styled.div`
  margin: 120px auto 0px;

  ${media.mobile`
    margin: 80px auto 0px;
  `};
`;

var Components = {
  ContentfulContentInterviewQA: ArticleQA,
  ContentfulContentMythFact: ArticleMythFact,
  ContentfulContentInterviewStandaloneAnswer: ArticleStandaloneAnswer,
  ContentfulContentStandaloneImage: ArticleStandaloneImage,
  ContentfulContentBlockquote: ArticleBlockQuote,
  ContentfulContentGeneric: ArticleContentGeneric,
  ContentfulContentInterviewBio: ArticleInterviewerBio,
};

export default class Content extends React.Component {
  constructor(props) {
    super(props);

    let {
      title,
      pageTitle,
      slug,
      heroImage,
      previewText,
    } = this.props.data.contentfulArticleFreeform;

    this.state = {
      showVideoOverlay: false,
      seo: {
        pagePath: `${config.contentHubArticlesUrl}/${slug}`,
        title: pageTitle || `${title} - Ritual`,
        description: previewText,
        image: {
          url: heroImage.share.src,
          width: heroImage.share.width,
          height: heroImage.share.height,
        },
      },
    };

    this.videoOverlay = React.createRef();
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Article",
    });

    this.props.updateLayoutState({
      navColor: "#ffffff",
      banner: false,
    });
  }

  showOverlay() {
    this.setState({
      showVideoOverlay: true,
    });
    if (this.videoOverlay) {
      this.videoOverlay.current.play();
    }
  }

  hideOverlay() {
    this.setState({
      showVideoOverlay: false,
    });
    if (this.videoOverlay) {
      this.videoOverlay.current.stop();
    }
  }

  renderComponent(componentNode, i, gutterContent) {
    let component = Components[componentNode.__typename];
    if (component) {
      let reactComponent = React.createElement(component, {
        data: componentNode,
        gutterContent: gutterContent,
        key: `component-` + i,
      });
      return reactComponent;
    } else {
      return null;
    }
  }

  render() {
    let { showVideoOverlay } = this.state;
    let { contentfulArticleFreeform } = this.props.data;
    let {
      articleVideo,
      primaryContent,
      essentialTakeaways,
      relatedArticles,
      products,
      experts,
      heroBackgroundColor,
      desktopImage,
      mobileImage,
    } = contentfulArticleFreeform;
    const { location } = this.props;

    let readTime = calculateReadingTime(primaryContent);

    const productsHasContent = products && products.length > 0;
    const hasProducts =
      productsHasContent && products[0].__typename === "ContentfulProduct";
    const hasCategories =
      productsHasContent &&
      products[0].__typename === "ContentfulProductCategory";

    const productSku = location.state ? location.state.productSku : "";

    return (
      <>
        <ArticleSEO {...this.state.seo} />

        {productSku && (
          <StickyProductNav
            sku={productSku}
            ctaLocation={"Articles Sticky Nav"}
            scrollOffset={150}
            showLearnMore={true}
            showTopBar={true}
          />
        )}

        <article>
          <PageHero
            id="article-hero"
            backgroundColor={heroBackgroundColor}
            imageMobile={mobileImage}
            imageObjectPosition={"10% 50%"}
            imageDesktop={desktopImage}
          >
            <ArticleHero
              readTime={readTime}
              onClick={this.showOverlay.bind(this)}
              {...contentfulArticleFreeform}
            />
          </PageHero>

          <ArticleReadProgress />

          <ArticleContainer className="article-container">
            <h2 className="sr-only">
              <Text
                id="template.article.content.heading"
                defaultMessage="Article Content"
              />
            </h2>
            {primaryContent &&
              primaryContent.map((c, i) => {
                let gutterContent =
                  i === 0 ? (
                    <ArticleEssentialTakeaways content={essentialTakeaways} />
                  ) : (
                    false
                  );
                return this.renderComponent(c, i, gutterContent);
              })}
            <ArticleShare data={contentfulArticleFreeform} />
            {experts && <ArticleExperts data={contentfulArticleFreeform} />}
          </ArticleContainer>
        </article>

        <CardsSection>
          {hasProducts && !hasCategories && (
            <ProductCardContainer>
              <ProductCards
                products={products}
                floatRightSingleProductCard={true}
              />
            </ProductCardContainer>
          )}

          {hasCategories && (
            <ProductCategoryCards
              categories={products}
              className="mt-7 mt-md-9 mb-7 mb-md-9"
            />
          )}
        </CardsSection>

        <ArticleRelated data={relatedArticles} />

        {articleVideo && (
          <VideoOverlay
            ref={this.videoOverlay}
            articleVideo={articleVideo}
            onClick={this.hideOverlay.bind(this)}
            visible={showVideoOverlay}
          />
        )}
      </>
    );
  }
}

export const pageQuery = graphql`
  fragment expandPrimaryContent on ContentfulArticleFreeform {
    primaryContent {
      __typename
      ... on ContentfulContentGeneric {
        ...genericContentBlock
      }
      ... on ContentfulContentBlockquote {
        ...blockQuoteBlock
      }
      ... on ContentfulContentInterviewQA {
        ...articleQABlock
      }
      ... on ContentfulContentMythFact {
        ...articleMythFactBlock
      }
      ... on ContentfulContentInterviewStandaloneAnswer {
        ...articleAnswerBlock
      }
      ... on ContentfulContentStandaloneImage {
        ...standaloneImageBlock
      }
      ... on ContentfulContentInterviewBio {
        ...interviewerBioContentBlock
      }
    }
  }

  fragment articleProductLinks on ContentfulArticleFreeform {
    products {
      __typename
      ... on ContentfulProduct {
        name {
          name
          childMarkdownRemark {
            html
          }
        }
        slug
        shortDescription
        summary
        cardImage {
          title
          file {
            url
          }
          fluid(maxWidth: 570, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        cardBackgroundColor
      }
      ... on ContentfulProductCategory {
        categoryName
        cardImage {
          title
          description
          fluid(maxWidth: 580, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        backgroundColor
        shopLandingReference {
          slug
        }
      }
    }
  }

  query FreeformArticleQuery($locale: String!, $slug: String!) {
    contentfulArticleFreeform(
      node_locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      title
      category {
        title
      }
      pageTitle
      slug
      previewText
      heroBackgroundColor
      heroSecondaryColor
      essentialTakeaways {
        childMarkdownRemark {
          html
        }
      }
      desktopImage: heroImage {
        description
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileImage: heroImage {
        description
        fluid(maxHeight: 912, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImage {
        file {
          url
        }
        desktop: fluid(maxWidth: 1920, quality: 90, cropFocus: TOP) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        mobile: fixed(width: 960, height: 560, quality: 90, cropFocus: LEFT) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
        share: resize(width: 1200, height: 630, quality: 90, cropFocus: LEFT) {
          src
          width
          height
        }
      }
      heroImageMobile {
        fixed(width: 375, height: 552, quality: 100, cropFocus: TOP) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      articleVideo {
        title
        description {
          description
        }
        videoLength
        sources {
          id
          file {
            url
            contentType
          }
          description
        }
        captions {
          file {
            url
          }
          description
        }
      }
      ...expandPrimaryContent
      ...articleProductLinks
      expertsHeading
      expertsSubheading
      experts {
        name
        title
        currentTitle
        articleTitle
        bio {
          bio
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          description
          fluid(maxWidth: 400, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      relatedArticles {
        __typename
        title
        slug
        previewText
        heroBackgroundColor
        heroSecondaryColor
        ...expandPrimaryContent
        category {
          slug
          title
        }
        heroImage {
          file {
            url
          }
          desktop: fixed(
            width: 960
            height: 560
            quality: 100
            cropFocus: LEFT
          ) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          mobile: fixed(
            width: 960
            height: 560
            quality: 100
            cropFocus: LEFT
          ) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
  }
`;
