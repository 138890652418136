import $ from "jquery";
import React from "react";
import styled from "styled-components";
import Row from "../Row";
import GutterElement from "./GutterElement";
import Img from "gatsby-image";
import { media, Color } from "../../utils/style";
import { graphql } from "gatsby";

const ImageModule = styled(Row)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ImageCol = styled.div.attrs({
  className: "col-12 col-md-7 offset-md-1",
})``;

const ImageWrapper = styled.div`
  border: 4px solid ${Color.ritualBlue};
  padding: 25px 35px;

  ${media.mobile`
    padding: 10px 15px;
    border-width: 2px;
  `} img {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

export default class ArticleStandaloneImage extends React.Component {
  constructor(props) {
    super(props);
    this.annotation = null;
    this.imageRef = null;
    this.state = {
      annotationTop: 0,
      hideGutter: false,
    };
  }

  setAnnotationTop(top) {
    this.setState({
      annotationTop: top + $(this.imageRef).outerHeight(),
    });
  }

  setHideGutter(hideGutter) {
    this.setState({
      hideGutter: hideGutter,
    });
  }

  render() {
    let {
      data: { image, gutterItem },
      gutterContent,
    } = this.props;
    let firstGutterItem = gutterItem ? gutterItem[0] : null;
    let { annotationTop, hideGutter } = this.state;
    return (
      <ImageModule>
        <GutterElement
          {...firstGutterItem}
          hideGutter={hideGutter}
          top={annotationTop}
          overrideContent={gutterContent}
          className="d-md-block"
        />
        <ImageCol>
          <ImageWrapper ref={r => (this.imageRef = r)}>
            <Img
              fluid={image.fluid}
              alt={image.description}
              style={{
                maxWidth: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          </ImageWrapper>
        </ImageCol>
      </ImageModule>
    );
  }
}

export const myQuery = graphql`
  fragment standaloneImageBlock on ContentfulContentStandaloneImage {
    title
    image {
      description
      file {
        url
      }
      fluid(maxWidth: 960, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    gutterItem {
      __typename
      ... on ContentfulGutterAnnotation {
        ...gutterSummary
      }
      ... on ContentfulGutterCta {
        ...gutterCtaSummary
      }
      ... on ContentfulGutterImage {
        ...gutterImageSummary
      }
    }
  }
`;
