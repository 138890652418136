import React from "react";
import styled from "styled-components";
import { Color, responsive } from "../../utils/style";
import $ from "jquery";

// Animations
import ScrollMagic from "scrollmagic";
import gsap from "gsap";

const ProgressBar = styled.div`
  position: fixed;
  bottom: -4px;
  top: 52px;
  left: 0;
  height: 4px;
  background-color: ${Color.ritualYellow};
  display: block;
  z-index: 100;

  ${responsive.md`
    top: 64px;
  `}
`;

export default class ArticleReadProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      topPosition: 0,
    };
    this.timeout = null;
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.initializeScrollMagic();
      this.updateScrollMagicOnResize();
    }, 1000);
    $(window).on("scroll.readHandler", this.handleScrollPosition.bind(this));
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.destroyScrollMagic();
    $(window).off("scroll.readHandler");
  }

  handleScrollPosition() {
    const headerHeight = $("header").outerHeight();
    const height = $("#article-hero").height() - headerHeight;
    const prevState = this.state.scrolled;
    if ($(window).scrollTop() >= height && !prevState) {
      this.setState({
        scrolled: true,
      });
    } else if ($(window).scrollTop() < height && prevState) {
      this.setState({
        scrolled: false,
      });
    }
  }

  updateScrollMagicOnResize() {
    $(window).on(
      "resize.readprogress",
      this.debouncedUpdateScrollMagic.bind(this),
    );
  }

  debouncedUpdateScrollMagic() {
    if (this.scrollMagicTimeout) {
      clearTimeout(this.scrollMagicTimeout);
    }
    this.scrollMagicTimeout = setTimeout(() => {
      this.updateScrollMagic();
    }, 200);
  }

  updateScrollMagic() {
    this.destroyScrollMagic();
    this.initializeScrollMagic();
  }

  destroyScrollMagic() {
    if (this.controller) {
      this.controller.destroy(true);
    }
    $(window).off("resize.readprogress");
  }

  initializeScrollMagic() {
    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: {},
    });
    this.initializeProgressBar();
  }

  initializeProgressBar() {
    let articleLength = $(".article-container").innerHeight();
    let halfViewport = $(window).innerHeight() / 4;

    new ScrollMagic.Scene({
      triggerElement: ".article-container",
      duration: articleLength + halfViewport + 480,
    })
      .setTween(this.progressTween(articleLength, halfViewport))
      .addTo(this.controller);
  }

  progressTween(barLength, stationaryLength) {
    let timeline = gsap.timeline();
    return timeline
      .fromTo(
        ".read-bar",
        {
          css: {
            right: "100%",
          },
          ease: "none",
        },
        {
          duration: barLength,
          css: {
            right: "0%",
          },
          ease: "none",
        },
      )
      .delay(stationaryLength)
      .fromTo(
        ".read-bar",
        {
          css: {
            height: "4px",
          },
        },
        {
          duration: 480,
          css: {
            height: "0px",
          },
        },
      );
  }

  render() {
    let { scrolled } = this.state;

    return <ProgressBar className="read-bar" />;
  }
}
