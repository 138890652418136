import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Container from "../Container";
import MagicLink from "../MagicLink";

import { rem, Font, media, responsive } from "../../utils/style";
import metrics from "../../utils/metrics";
import NameMap from "../../utils/nameMap";

const ProductContainer = styled(Container).attrs({
  id: "product-cards",
})`
  margin: 120px auto;
  margin-top: ${props => props.noTopMargin && 0};
  position: relative;
  z-index: 10;

  ${media.tablet`
    margin: 40px auto 80px;
    margin-top: ${props => props.noTopMargin && 0};
  `};
`;

const Grid = styled.div.attrs({
  id: "product-cards_grid",
})`
  display: grid;
  grid-template-columns: repeat(${p => p.numberOfProducts}, 1fr);
  grid-gap: 30px;
  width: ${p => (p.numberOfProducts === 1 ? "50%" : "auto")};
  margin: ${p => (p.numberOfProducts === 1 ? "0 -15px 0 25%" : "0 -15px")};

  ${media.notDesktop`
    grid-gap: 20px;
  `}

  ${media.tablet`
  ${p =>
    p.numberOfProducts > 2 &&
    `
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  `};
  `}

  ${media.mobile`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${p => p.numberOfProducts}, 1fr);
    grid-gap: 24px;
    padding: 0 20px;
    margin: 0 -15px;
    width: auto;
  `}
`;

const ProductLink = styled(MagicLink).attrs(p => ({
  id: `product-cards_grid_card-${p.index}`,
}))`
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  height: ${p => (p.numberOfProducts > 2 ? "370px" : "570px")};
  padding: ${p =>
    p.numberOfProducts > 2 ? "24px 24px 18px" : "40px 40px 24px"};
  background-color: ${p => (p.backgroundColor ? p.backgroundColor : "#CCC")};
  z-index: 1;

  img {
    transition: transform 0.2s ease-in-out !important;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  ${media.notDesktop`
    height: ${p => (p.numberOfProducts > 2 ? "294px" : "454px")};
    padding: 24px;
  `}

  ${media.tablet`
    height: ${p => (p.numberOfProducts > 2 ? "280px" : "340px")};
    width: ${p => (p.numberOfProducts > 2 ? "45%" : "340px")};
    margin: ${p => (p.numberOfProducts > 2 ? "8px" : "0")};
  `}

  ${media.mobile`
    height: 280px
    width: 100%;
    margin: 0;

    &:hover img {
      transform: none;
    }
  `}
`;

const ImageWrapper = styled.div.attrs(p => ({
  id: `product-cards_grid_card-${p.index}_image-wrapper`,
}))`
  background: #f3f3f7;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const ProductContent = styled.div.attrs(p => ({
  id: `product-cards_grid_card-${p.index}_content`,
}))`
  position: relative;
  z-index: 1;
`;

const ProductTitle = styled.h2.attrs(p => ({
  id: `product-cards_grid_card-${p.index}_content_title`,
}))`
  ${Font.circular}
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.3px;
  font-weight: 500;


  ${responsive.lg`
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    margin-bottom: ${p => (p.numberOfProducts > 2 ? "8px" : "16px")};
  `}

  em {
    ${Font.dutch}
    font-weight: 300;
  }

`;

const ProductDescription = styled.p.attrs(p => ({
  id: `product-cards_grid_card-${p.index}_content_description`,
}))`
  ${Font.dutch}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin-bottom: 0;

  ${responsive.lg`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    margin-bottom: 8px;
  `}
`;

function trackCTAClick(title, location, url, event) {
  metrics.track("CTA Clicked", {
    title: title,
    nonInteraction: false,
    location: location,
  });
}

export default function ProductCards({ products, ...props }) {
  const numberOfProducts = products.length;

  return (
    <ProductContainer noTopMargin={props.noTopMargin}>
      <Grid numberOfProducts={numberOfProducts}>
        {products.map((product, index) => (
          <ProductCard
            index={index}
            key={NameMap(product.name).plain}
            product={product}
            numberOfProducts={numberOfProducts}
            {...props}
          />
        ))}
      </Grid>
    </ProductContainer>
  );
}

function ProductCard({ product, numberOfProducts, index }) {
  const productName = NameMap(product.name).plain;
  const name = NameMap(product.name).html;

  return (
    <ProductLink
      index={index}
      key={product.slug}
      backgroundColor={product.cardBackgroundColor}
      numberOfProducts={numberOfProducts}
      onClick={event =>
        trackCTAClick(
          productName,
          "Product Card",
          `/products/${product.slug}`,
          event,
        )
      }
      to={`/products/${product.slug}`}
      hrefLang="x-default"
      aria-label={`Learn more about ${productName}`}
    >
      <ImageWrapper index={index}>
        <Img
          id={`product-cards_grid_card-${index}_image-wrapper_image`}
          backgroundColor={product.cardBackgroundColor}
          fluid={product.cardImage.fluid}
          fadeIn={false}
          alt={productName}
          style={{
            userSelect: "none",
            userDrag: "none",
            pointerEvents: "none",
            touchCallout: "none",
          }}
        />
      </ImageWrapper>
      <ProductContent index={index}>
        <ProductTitle
          index={index}
          numberOfProducts={numberOfProducts}
          dangerouslySetInnerHTML={{ __html: name }}
        />
        <ProductDescription index={index}>
          {product.shortDescription}
        </ProductDescription>
      </ProductContent>
    </ProductLink>
  );
}

export const componentFragment = graphql`
  fragment ProductCardFragment on ContentfulProduct {
    __typename
    name {
      name
      childMarkdownRemark {
        html
      }
    }
    slug
    shortDescription
    summary
    cardImage {
      file {
        url
      }
      fluid(maxWidth: 1710, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    cardBackgroundColor
  }
`;
