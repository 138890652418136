import React from "react";
import $ from "jquery";
import styled from "styled-components";
import GutterElement from "./GutterElement";
import ContentWithCallout from "./ContentWithCallout";
import Row from "../Row";
import Text from "../Text";
import { Font, media, Color, rem } from "../../utils/style";
import { graphql } from "gatsby";

const Article = styled(Row)`
  color: ${Color.ritualBlue};
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Myth = styled.div`
  position: relative;
  padding-left: 70px;
  margin: 0 0 26px;

  ${media.mobile`
    padding-left: 45px;
  `};
`;

const MythIcon = styled.i`
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 33px;
  height: 33px;
  border-radius: 32px;
  background-color: ${Color.ritualRed};

  &:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 16px;
    border-radius: 2.5px;
    background-color: ${Color.white};
    top: calc(50% - (16px / 2));
    left: calc(50% - (3px / 2));
    transform: rotate(45deg);
  }

  &:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 16px;
    border-radius: 2.5px;
    background-color: ${Color.white};
    top: calc(50% - (16px / 2));
    left: calc(50% - (3px / 2));
    transform: rotate(-45deg);
  }
`;

const MythTitle = styled.h3`
  ${Font.circular}
  color: ${Color.ritualRed};
  font-size: ${rem(34)};
  line-height: ${rem(40)};
  font-weight: 400;
  margin: 0 0 ${rem(6)};
  letter-spacing: -0.5px;

  ${media.mobile`
    font-size: ${rem(30)};
    line-height: ${rem(38)};
  `}
`;

const ArticleMyth = styled.p`
  ${Font.circular}
  color: ${Color.ritualRed};
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  font-weight: 400;
  margin: 0;

  ${media.mobile`
    font-size: ${rem(22)};
    line-height: ${rem(30)};
  `}
`;

const FactContent = styled.div`
  position: relative;
  padding-left: 70px;

  ${media.mobile`
    padding-left: 45px;
  `}

  h1,
  h2 {
    ${Font.circular}
    font-size: ${rem(24)};
    line-height: ${rem(32)};
    font-weight: 400;
    color: ${Color.ritualBlue};
    margin: 0 0 ${rem(26)};

    ${media.mobile`
      font-size: ${rem(22)};
      line-height: ${rem(30)};
    `}
  }
`;

const FactIcon = styled.i`
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 33px;
  height: 33px;
  border-radius: 32px;
  background-color: ${Color.ritualBlue};

  &:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 16px;
    border-radius: 2.5px;
    background-color: ${Color.white};
    top: calc(50% - (16px / 2));
    left: 50%;
    transform: rotate(45deg);
  }

  &:after {
    content: "";
    position: absolute;
    width: 4px;
    height: 8px;
    border-radius: 2.5px;
    background-color: ${Color.white};
    transform: rotate(-45deg);
    top: calc(50% - 2px);
    left: 10px;
  }
`;

const FactTitle = styled.h3`
  ${Font.circular}
  font-size: ${rem(34)};
  line-height: ${rem(40)};
  font-weight: 400;
  color: ${Color.ritualBlue};
  margin: 0 0 ${rem(6)};
  letter-spacing: -0.5px;

  ${media.mobile`
    font-size: ${rem(30)};
    line-height: ${rem(38)};
  `}
`;

export default class ArticleMythFact extends React.Component {
  constructor(props) {
    super(props);
    this.annotation = null;
    this.mythRef = null;
    this.state = {
      annotationTop: 0,
      hideGutter: false,
    };
  }

  setAnnotationTop(top) {
    this.setState({
      annotationTop: top + $(this.mythRef).outerHeight(),
    });
  }

  setHideGutter(hideGutter) {
    this.setState({
      hideGutter: hideGutter,
    });
  }

  render() {
    let { myth, fact, gutterItem } = this.props.data;
    let { annotationTop, hideGutter } = this.state;
    let firstGutterItem = gutterItem ? gutterItem[0] : null;
    return (
      <Article>
        <GutterElement
          ref={p => {
            this.annotation = p;
          }}
          {...firstGutterItem}
          top={annotationTop}
          hideGutter={hideGutter}
          className="d-md-block"
        />
        <div className="col-12 col-md-7 offset-md-1">
          <Myth ref={p => (this.mythRef = p)}>
            <MythIcon />
            <MythTitle>
              <Text id="hub.myth-title" defaultMessage="Myth" />
            </MythTitle>
            <ArticleMyth>{myth}</ArticleMyth>
          </Myth>
          <FactContent
            ref={r => {
              this.answerRef = r;
            }}
          >
            <FactIcon />
            <FactTitle>
              <Text id="hub.fact-title" defaultMessage="Fact" />
            </FactTitle>
            <ContentWithCallout
              onCalloutMoved={this.setAnnotationTop.bind(this)}
              hideGutter={this.setHideGutter.bind(this)}
              content={fact.childMarkdownRemark.html}
            />
          </FactContent>
        </div>
      </Article>
    );
  }
}

export const myQuery = graphql`
  fragment articleMythFactBlock on ContentfulContentMythFact {
    myth
    fact {
      childMarkdownRemark {
        html
      }
    }
    contentBody: fact {
      childMarkdownRemark {
        timeToRead
      }
    }
    gutterItem {
      ... on ContentfulGutterAnnotation {
        ...gutterSummary
      }
      ... on ContentfulGutterCta {
        ...gutterCtaSummary
      }
      ... on ContentfulGutterImage {
        ...gutterImageSummary
      }
    }
  }
`;
