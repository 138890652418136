import React from "react";
import styled from "styled-components";
import Row from "../Row";
import GutterElement from "./GutterElement";
import ContentWithCallout from "./ContentWithCallout";
import { processHtml } from '../../utils/markdown';
import { graphql } from "gatsby";

import { Font, media, rem, Color } from "../../utils/style";

const ContentRow = styled(Row)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Content = styled.div.attrs({
  className: "col-12 col-md-7 offset-md-1",
})`
  * {
    color: ${Color.ritualBlue};
  }

  .gatsby-resp-image-wrapper {
    margin: 30px 0;
  }

  h3 {
    ${Font.circular}
    font-size: ${rem(24)};
    line-height: ${rem(32)};
    margin: 0 0 ${rem(26)};

    ${media.mobile`
      font-size: ${rem(22)};
      line-height: ${rem(30)};
    `}
  }

  p {
    ${Font.dutch}
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    margin: 0 0 ${rem(26)};

    &:last-of-type {
      margin: 0;
    }

    ${media.mobile`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `}
  }
`;

export default class ArticleContentGeneric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      annotationTop: 0,
      hideGutter: false,
    };
  }

  setAnnotationTop(top) {
    this.setState({
      annotationTop: top,
    });
  }

  setHideGutter(hideGutter) {
    this.setState({
      hideGutter: hideGutter,
    });
  }

  render() {
    let {
      data: { body, gutterItem },
      gutterContent,
    } = this.props;
    let firstGutterItem = gutterItem ? gutterItem[0] : null;
    let { annotationTop, hideGutter } = this.state;
    const content = processHtml(body.childMarkdownRemark.html);

    return (
      <ContentRow>
        <GutterElement
          {...firstGutterItem}
          hideGutter={hideGutter}
          top={annotationTop}
          overrideContent={gutterContent}
          className="d-md-block"
        />
        <Content>
          <ContentWithCallout
            gutterItem={firstGutterItem}
            hideGutter={this.setHideGutter.bind(this)}
            onCalloutMoved={this.setAnnotationTop.bind(this)}
            content={content}
          />
        </Content>
      </ContentRow>
    );
  }
}

export const myQuery = graphql`
  fragment genericContentBlock on ContentfulContentGeneric {
    body {
      childMarkdownRemark {
        html
      }
    }
    contentBody: body {
      childMarkdownRemark {
        timeToRead
      }
    }
    gutterItem {
      ... on ContentfulGutterAnnotation {
        ...gutterSummary
      }
      ... on ContentfulGutterCta {
        ...gutterCtaSummary
      }
      ... on ContentfulGutterImage {
        ...gutterImageSummary
      }
    }
  }
`;
