import React from "react";
import styled from "styled-components";
import { Color, Font, responsive, rgba, rem } from "../../utils/style";
import Row from "../Row";
import Img from "gatsby-image";

// Services
import intl from "../../services/intl";

const StyledRow = styled(Row)`
  margin-top: 80px;
`;

const StyledColumn = styled.div.attrs({
  className: "col-12 col-md-7 offset-md-5",
})``;

const ExpertsSection = styled.div`
  h3 {
    color: ${Color.ritualBlue};
    font-size: ${rem(30)};
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: ${rem(36)};
    margin-bottom: 16px;

    ${responsive.md`
      font-size: ${rem(48)};
      letter-spacing: -1.4px;
      line-height: ${rem(54)};
    `}
  }

  p {
    ${Font.dutch};
    color: ${Color.ritualBlue};
    font-size: ${rem(18)};
    font-weight: normal;
    letter-spacing: 0px;
    line-height: ${rem(28)};
    margin-bottom: 32px;

    ${responsive.md`
      font-size: ${rem(20)};
      font-weight: normal;
      line-height: ${rem(30)};
      margin-bottom: 40px;
    `}
  }
`;

const ExpertsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;

  p {
    ${Font.circular}
  }
`;

const MobileExpert = styled.div.attrs({
  className: "d-block d-md-none",
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-bottom: 32px;

  p {
    color: ${rgba(Color.ritualBlue, 0.56)}
    font-size: ${rem(14)};
    font-weight: 500;
    letter-spacing: 0px;
    line-height: ${rem(24)};
    margin-bottom: 0;
  }

   > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;

    .gatsby-image-wrapper {
      height: 84px;
      width: 84px;
      border-radius: 135px;
      margin-right: 14px;
      margin-bottom: 0;
    }

    p {
      color: ${Color.ritualBlue};
      font-size: ${rem(16)};
      font-weight: 500;
      letter-spacing: 0px;
      line-height: ${rem(26)};
      margin-bottom: 0;
    }
  }
`;

const DesktopExpert = styled.div.attrs({
  className: "d-none d-md-flex",
})`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  .gatsby-image-wrapper {
    border-radius: 135px;
    margin-right: 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    justift-content: left;
    align-items: top;

    p:first-of-type {
      font-size: ${rem(20)};
      font-weight: 500;
      letter-spacing: 0px;
      line-height: ${rem(30)};
      margin-bottom: 16px;
    }

    p:last-of-type {
      color: ${rgba(Color.ritualBlue, 0.56)};
      font-size: ${rem(16)};
      font-weight: 300;
      letter-spacing: 0px;
      line-height: ${rem(26)};
      margin-bottom: 0;
    }
  }
`;

export default class ArticleExperts extends React.Component {
  renderExpert(expert) {
    const { image, name, articleTitle, currentTitle, bio } = expert;
    const expertTitle = articleTitle
      ? articleTitle
      : `${name}, ${currentTitle}`;

    if (!image || !name || !bio || !currentTitle) return;

    return (
      <>
        <MobileExpert>
          <div>
            <Img
              className=""
              fluid={image.fluid}
              loading="eager"
              alt={image.title}
              color={Color.white}
              style={{
                position: "relative",
                width: "84px",
                height: "84px",
                minWidth: "84px",
                zIndex: "1",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
            <p>{expertTitle}</p>
          </div>
          <p>{bio.bio}</p>
        </MobileExpert>

        <DesktopExpert>
          <Img
            className=""
            fluid={image.fluid}
            loading="eager"
            alt={image.title}
            color={Color.white}
            style={{
              position: "relative",
              width: "170px",
              height: "170px",
              minWidth: "170px",
              zIndex: "1",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
          <div>
            <p>{expertTitle}</p>
            <p>{bio.bio}</p>
          </div>
        </DesktopExpert>
      </>
    );
  }

  render() {
    const { experts, expertsHeading, expertsSubheading } = this.props.data;

    const heading =
      expertsHeading || intl.t("hub.exports.heading", "Meet the Experts");
    const subheading =
      expertsSubheading ||
      intl.t(
        "hub.exports.subheading",
        "The experts have helped inform the contents of this article.",
      );
    return (
      <StyledRow>
        <StyledColumn>
          <ExpertsSection>
            <h3>{heading}</h3>
            <p>{subheading}</p>
            <ExpertsContainer>
              {experts.map(expert => {
                return this.renderExpert(expert);
              })}
            </ExpertsContainer>
          </ExpertsSection>
        </StyledColumn>
      </StyledRow>
    );
  }
}
