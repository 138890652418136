import React from "react";
import styled from "styled-components";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";

// Services
import intl from "../../services/intl";

// Components
import Row from "../Row";
import Text from "../Text";

// Utils
import { Font, media, Color, rem } from "../../utils/style";
import config from "../../utils/siteConfig";
import metrics from "../../utils/metrics";
import { Icons, Social } from "../../utils/svg";

const ShareRow = styled(Row)`
  margin-top: 60px;
`;

const StyledColumn = styled.div.attrs({
  className: "col-12 col-md-3 offset-md-5",
})`
  position: relative;
  padding-top: ${rem(30)};

  &:after {
    content: "";
    position: absolute;
    top: -3px;
    left: 0;
    width: 300%;
    height: 3px;
    background-color: ${Color.ritualBlue};

    ${media.mobile`
      width: auto;
      right: 20px;
      left: 20px;
    `};
  }
`;

const ShareTitle = styled.h2`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  font-weight: 400;
  margin: 0;
`;

const ShareColumn = styled.div.attrs({
  className: "col-2",
})`
  overflow: hidden;

  & > div {
    margin: 2px 0 0;
    cursor: pointer;
    float: right;
    width: 22px;
    height: 20px;
    text-align: center;
    transition: opacity 200ms ease-in-out;

    [data-whatintent="mouse"] &:focus,
    [data-whatintent="touch"] &:focus {
      outline: none;
    }

    &:hover {
      opacity: 0.56;
    }

    svg {
      vertical-align: middle;
    }
  }
`;

const SVGWrapper = styled.div`
  height: ${p => (p.height ? p.height : 18)}px;
  width: auto;
  position: relative;

  svg {
    height: 100%;
  }
`;

export default class ArticleShare extends React.Component {
  getMailto() {
    let subject = "Subject Line Here";
    let body = "Body goes here";

    let mailto = `mailto:?subject=${subject}&body=${body}`.replace(
      /\s/g,
      "%20",
    );
    return mailto;
  }

  trackWithSegment(location) {
    metrics.track("Article Shared", {
      location: location,
    });
  }

  render() {
    let { title, slug, heroImage } = this.props.data;
    let shareUrl = `${config.siteUrl}/articles/${slug}`;
    let shareImageUrl = `https:${heroImage.share.src}`;
    let email = {
      subject: intl.t(
        "hub.article.share.email-subject",
        "I was skeptical too...",
      ),
      body: intl.t(
        "hub.article.share.email-body",
        `I read "{title}" and thought you'd want the facts too. Check it out here: {shareUrl}`,
        { title, shareUrl },
      ),
    };
    return (
      <ShareRow>
        <StyledColumn>
          <Row>
            <div className="col-4">
              <ShareTitle>
                <Text id="hub.article.share.title" defaultMessage="Share" />
              </ShareTitle>
            </div>
            <ShareColumn>
              <FacebookShareButton
                url={shareUrl}
                beforeOnClick={() => {
                  this.trackWithSegment("Facebook");
                }}
              >
                <SVGWrapper>
                  <Social.Facebook />
                </SVGWrapper>
              </FacebookShareButton>
            </ShareColumn>
            <ShareColumn>
              <TwitterShareButton
                url={shareUrl}
                title={title}
                via={"ritual"}
                beforeOnClick={() => {
                  this.trackWithSegment("Twitter");
                }}
              >
                <SVGWrapper>
                  <Social.Twitter />
                </SVGWrapper>
              </TwitterShareButton>
            </ShareColumn>
            <ShareColumn>
              <PinterestShareButton
                url={shareUrl}
                media={shareImageUrl}
                description={title}
                beforeOnClick={() => {
                  this.trackWithSegment("Pinterest");
                }}
              >
                <SVGWrapper>
                  <Social.Pinterest />
                </SVGWrapper>
              </PinterestShareButton>
            </ShareColumn>
            <ShareColumn>
              <EmailShareButton
                url={shareUrl}
                subject={email.subject}
                body={email.body}
                beforeOnClick={() => {
                  this.trackWithSegment("Email");
                }}
              >
                <SVGWrapper height={14}>
                  <Icons.Email />
                </SVGWrapper>
              </EmailShareButton>
            </ShareColumn>
          </Row>
        </StyledColumn>
      </ShareRow>
    );
  }
}
