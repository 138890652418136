import React from "react";
import styled from "styled-components";
import Row from "../Row";
import GutterElement from "./GutterElement";
import ContentWithCallout from "./ContentWithCallout";
import { Color } from "../../utils/style";
import { graphql } from "gatsby";

const Article = styled(Row)`
  color: ${Color.ritualBlue};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export default class ArticleStandaloneAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.annotation = null;
    this.state = { annotationTop: 0, hideGutter: false };
  }

  setAnnotationTop(top) {
    this.setState({
      annotationTop: top,
    });
  }

  setHideGutter(hideGutter) {
    this.setState({
      hideGutter: hideGutter,
    });
  }

  render() {
    let { answer, gutterItem } = this.props.data;
    let firstGutterItem = gutterItem ? gutterItem[0] : null;
    let { annotationTop, hideGutter } = this.state;
    return (
      <Article>
        <GutterElement
          ref={p => {
            this.annotation = p;
          }}
          {...firstGutterItem}
          hideGutter={hideGutter}
          top={annotationTop}
          className="d-none d-md-block"
        />
        <div className="col-12 col-md-7 offset-md-1">
          <ContentWithCallout
            type="answer"
            hideGutter={this.setHideGutter.bind(this)}
            gutterItem={firstGutterItem}
            onCalloutMoved={this.setAnnotationTop.bind(this)}
            content={answer.childMarkdownRemark.html}
          />
        </div>
      </Article>
    );
  }
}

export const myQuery = graphql`
  fragment articleAnswerBlock on ContentfulContentInterviewStandaloneAnswer {
    answer {
      childMarkdownRemark {
        html
      }
    }
    contentBody: answer {
      childMarkdownRemark {
        timeToRead
      }
    }
    gutterItem {
      ... on ContentfulGutterAnnotation {
        ...gutterSummary
      }
      ... on ContentfulGutterCta {
        ...gutterCtaSummary
      }
      ... on ContentfulGutterImage {
        ...gutterImageSummary
      }
    }
  }
`;
