import React from "react";
import styled from "styled-components";
import { Font, media, rem, Color } from "../../utils/style";
import Text from "../Text";

const Wrapper = styled.div`
  border: 2px solid ${Color.ritualBlue};
  padding: 40px;

  ${media.notDesktop`
    padding: 30px 24px;
    margin-bottom: 60px;
  `};
`;

const Content = styled.div`
  text-align: left;

  ul {
    padding-left: 0;
    margin: 0;
  }

  li, p {
    ${Font.dutch}
    color: ${Color.ritualBlue};
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    font-style: italic;
    margin: 0 0 20px;
    list-style-position: inside;

    &:last-child {
      margin-bottom: 0;
    }

    * {
      font: inherit;
      color: inherit;
    }

    ${media.notDesktop`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `}
  }
`;

const Title = styled.h3`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  font-weight: 400;
  text-align: left;
  letter-spacing: 0;
  margin: 0 0 16px;

  ${media.notDesktop`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `};
`;

export default class ArticleEssentialTakeaways extends React.Component {
  render() {
    let {
      childMarkdownRemark: { html },
    } = this.props.content;
    return (
      <Wrapper>
        <Title>
          <Text
            id="hub.essential-takeaways.title"
            defaultMessage="Essential Takeaways"
          />
        </Title>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </Wrapper>
    );
  }
}
