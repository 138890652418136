import React from "react";
import styled from "styled-components";
import Row from "../Row";
import Gutter from "../Gutter";
import GutterAnnotation from "./GutterAnnotation";
import { Font, media, rem, Color } from "../../utils/style";
import { graphql } from "gatsby";

const Quote = styled.blockquote.attrs({
  className: "col-12 col-md-7 offset-md-1",
})`
  margin-top: 30px;
  margin-bottom: 30px;

  p {
    position: relative;
    ${Font.circular}
    color: ${Color.ritualBlue};
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    letter-spacing: -0.5px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
    padding-left: 70px;

    ${media.mobile`
      padding-left: 45px;
      font-size: ${rem(22)};
      line-height: ${rem(30)};
      letter-spacing: -0.2px;
    `}

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 4px;
      top: 0;
      bottom: 0;
      background-color: ${Color.ritualBlue};

      ${media.mobile`
        width: 6px;
      `}
    }
  }
`;

export default class ArticleBlockQuote extends React.Component {
  renderGutter() {
    let {
      gutterContent,
      data: { gutterItem },
    } = this.props;
    let { annotationTop } = this.state;
    return gutterContent ? (
      <Gutter>{gutterContent}</Gutter>
    ) : (
      <GutterAnnotation {...gutterItem} top={annotationTop} />
    );
  }

  render() {
    let { quote } = this.props.data;
    return (
      <Row>
        <Gutter />
        <Quote
          dangerouslySetInnerHTML={{ __html: quote.childMarkdownRemark.html }}
        />
      </Row>
    );
  }
}

export const myQuery = graphql`
  fragment blockQuoteBlock on ContentfulContentBlockquote {
    quote {
      childMarkdownRemark {
        html
      }
    }
    contentBody: quote {
      childMarkdownRemark {
        timeToRead
      }
    }
  }
`;
